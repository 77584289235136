export class FileModule {
    static read(file, callback) {
        var reader = new FileReader();
        reader.onload = function (file) {
            var arrayBuffer = reader.result;
            var byteArray = new Uint8Array(arrayBuffer);
            callback(byteArray);
        }
        reader.readAsArrayBuffer(file);
    }
    static readJson(url) {
        return fetch(url).then(res => res.json()).then((out) => { return out; });
    }
    static convertJsonToCsv(jsonArray) {
        // Extract headers (keys from the first object in the JSON array)
        const headers = Object.keys(jsonArray[0]);
        const csvRows = [];
        // Add headers as the first row
        csvRows.push(headers.join(","));
        // Map through the data and create CSV rows
        for (const row of jsonArray) {
          const values = headers.map((header) => {
            const escaped = ("" + row[header]).replace(/"/g, '""'); // Escape quotes
            return `"${escaped}"`; // Wrap values in quotes
          });
          csvRows.push(values.join(","));
        }
        // Join all rows with newlines
        return csvRows.join("\n");
      };
}