import React, { Component } from 'react';
import { HelperUser } from './../../../helpers/helper_user/helper_user';
import { HelperConfig } from './../../../helpers/helper_config/helper_config';
// components
import { PlatformNameComponent } from './../../components/platform_name/platform_name_component'

export class UserPage extends Component {
    constructor() {
        super();
        this.state = {
            user_types: []
        }
    }
    componentDidMount() {
        let user_type = HelperUser.getUserType();
        let myC = this;
        const urlParams = new URLSearchParams(window.location.search);
        let stay = urlParams.get('stay');
        HelperConfig.init().then(() => {
            if (user_type !== null && user_type !== "null" && !stay) {
                if (urlParams.get("location") && urlParams.get("params")) {
                    this.setUrl(urlParams.get("location") + "?" + urlParams.get("params"));
                } else {
                    let rol2Render = HelperUser.getRoleFromConfig(user_type);
                    this.setUrl(rol2Render.redirect2page);    
                }
            } else {
                HelperUser.getUserRolesFromDB().then((user) => {
                    if (!user.error && user.user_role) {
                        HelperUser.setUserRoles(user.user_role);
                        myC.setState({
                            user_types: user.user_role
                        });
                    } else {
                        HelperUser.removeUser();
                        myC.setUrl("login");
                    }
                })
            }
        });
    }
    setUrl(url) {
        if (url !== false) {
            var browserHistory = this.props.history;
            browserHistory.push("/" + url);
        } else {
            alert("URL not compatible");
        }
    }
    onClickLoginButton(role) {
        if (role.new_tab) {
            HelperUser.openAnotherPlatform(role.redirect2page, role.redirect_params)
        } else {
            HelperUser.setUserType(role.type);
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.get("location") && urlParams.get("params")) {
                this.setUrl(urlParams.get("location") + "?" + urlParams.get("params"));
            } else {
                this.setUrl(role.redirect2page);    
            }
        }
    }
    renderRoles() {
        let roles = this.state.user_types;
        if (roles) {
            return roles.map(user_type => {
                let rol2Render = HelperUser.getRoleFromConfig(user_type)
                if (rol2Render) {
                    return (
                        <div key={user_type} className="grid-item button_user_type_selectable" style={{ backgroundColor: rol2Render.color }} onClick={() => this.onClickLoginButton(rol2Render)}>
                            <div className="button_user_type_text"> {rol2Render.name_to_show}</div>
                            <div className="button_user_type_description"> {rol2Render.description}</div>
                        </div>
                    );
                }        
            });
        }
    }
    render() {
        return (
            <div className="grid-frame vertical user-page">
                <PlatformNameComponent type={"medium"}/>
                <div className="login-text">Login as: </div>
                <div className="user-types">
                    {this.renderRoles()}
                </div>
            </div>
        );
    }
}