import React, { Component } from 'react';

// Components
import { LoadingComponent } from '../../components/loading_component/loading_component';
import { ImageMenuIcons } from '../../components/icons_component/image_menu_icons';

// Helpers
import { HelperConfig } from './../../../helpers/helper_config/helper_config'
import { HelperUser } from '../../../helpers/helper_user/helper_user'
import { HelperStudies } from '../../../helpers/helper_studies/helper_studies'

// Actions file
import { onActionToPerform } from './../../../actions/actions';

export class PrismaReviewPage extends Component {

    constructor() {
        super();
        this.state = {
            studies_to_review: [],
            loaded: false
        }
        this.user = {};
    }

    componentDidMount() {
        if (!HelperUser.validateUserAuthentication()) {
            this.onActionToPerform("GENERAL", { action: "LOGOUT", value: "" });
        } else {
            HelperConfig.init().then(() => {
                this.user = HelperUser.getUser();
                HelperStudies.setCurrentProject("fedoc-arc");
                HelperStudies.loadPrismaReviewData(this.user.user_token).then((studies) => {
                    this.setState({
                        studies_to_review: studies,
                        loaded: true
                    });
                });
            });
        }
    }

    goToStudies() {
        var site = window.location.href;
        var new_path = site.replace("prisma-review", "user-page?stay=true")
        window.location.href = new_path;
    }

    handleButton(study, action, idx) {
        if (action === "back") {
            HelperStudies.validatedToCheckpoint(study.study_id, study.reviewer, study.editor);
        } else {
            let new_state = action === "exclude"? "Not valid" : (action === "include"? "Suboptimal" : "Consensus pending");
            HelperStudies.updateReviewState(study.study_id, study.reviewer, study.editor, new_state);
        }
        HelperStudies.deleteTeamReviewFromMinio(study.study_id);
        let new_list = this.state.studies_to_review.slice();
        new_list.splice(idx,1);
        this.setState({
            studies_to_review: new_list
        });
    }

    onActionToPerform(actionType, action) {
        onActionToPerform(actionType, action, this);
    }

    renderQCIcon(value) {
        let qc_icon = value === "bad"? "thumbsdown-filled" : "thumbsup-filled";
        let qc_color = value === "bad"? "#ff8383" : "#96ff83";                   
        return <ImageMenuIcons type={qc_icon} color={qc_color} width="31" height="31" />;
    }

    renderStudyButtons(study, idx) {
        let include_button = study.images.every(img => img.cycles_segmented !== 0);
        let action = (act) => { this.handleButton(study, act, idx) };
        return include_button? (
            <>
                <div className="study-button"><span onClick={() => action("back")}>Back for changes</span></div>
                <div className="study-button bad"><span onClick={() => action("exclude")}>Exclude study</span></div>
                <div className="study-button good"><span onClick={() => action("include")}>Include study</span></div>
            </>
        ) : (
            <>
                <div className="study-button"><span onClick={() => action("back")}>Back for changes</span></div>
                <div className="study-button bad"><span onClick={() => action("exclude")}>Exclude study</span></div>
            </>
        );
    }

    renderStudy(study, idx) {
        return (
            <div className="study-row" key={study.study_id}>
                <div className="header">
                    <span>Study ID:&nbsp;&nbsp;&nbsp;{study.study_id}</span>
                    {this.renderStudyButtons(study, idx)}
                </div>
                <div className="content">
                    {study.images.map(img => {
                        return (
                            <div className="image-box" key={img.image_filename}>
                                <img src={img.url} alt="" />
                                <div className="image-type-tag">{img.image_type}</div>
                                <div className="footer">
                                    Phase 1
                                    {this.renderQCIcon(img.qc_phase_1)}
                                    Phase 2
                                    {this.renderQCIcon(img.qc_phase_2)}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="divider"></div>
            </div>
        );
    }

    renderPageContent() {
        if (this.state.loaded) {
            return (
                <div className="studies-list">
                    {this.state.studies_to_review.map((study, idx) => {
                        return this.renderStudy(study, idx);
                    })}
                    <div style={{textAlign:"center"}}>No more studies to review</div>
                </div>
            );
        } else {
            return (
                <LoadingComponent
                    site={"prisma-pages"}
                />
            );
        }
    }

    render() {
        return (
            <div className="grid-frame vertical prisma-page">
                <div className="back-icon"> 
                    <div style={{display:"flex"}} onClick={() => { this.goToStudies() }}>
                        <svg viewBox="0 0 24 24" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <div className="back-text">Back to user page</div>
                    </div>
                </div>
                <div className="container" style={{height: window.innerHeight - 78}}>
                    {this.renderPageContent()}
                </div>
            </div>
        );
    }
}